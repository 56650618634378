
  
  .price-input[data-v-ed93e402] {
    background-color: transparent;
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
  }
  .price-input[data-v-ed93e402]:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .price-input[data-v-ed93e402]:read-only {
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgba(148, 163, 184, var(--tw-text-opacity));
  }
  .dark .price-input[data-v-ed93e402]:read-only {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }
  .modal-close-icon[data-v-00b6ad4f] {
    --tw-border-opacity: 1;
    border-color: rgba(15, 23, 42, var(--tw-border-opacity));
    border-radius: 9999px;
    border-width: 1px;
    height: 1.5rem;
    width: 1.5rem;
    -webkit-transition-property: background-color, border-color, color, fill,
      stroke, opacity, -webkit-box-shadow, -webkit-transform, filter,
      backdrop-filter;
    -o-transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform,
      filter, backdrop-filter;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition-duration: 0.15s;
    -o-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .dark .modal-close-icon[data-v-00b6ad4f] {
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }
  .modal-token-item[data-v-00b6ad4f]:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.7;
  }
  .dark .modal-token-item[data-v-00b6ad4f]:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
  }
  .modal-token-item[data-v-00b6ad4f] {
    cursor: pointer;
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 0.5rem 1.5rem;
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    grid-template-columns: 40px minmax(auto, 1fr);
  }
  .modal-token-item.disabled[data-v-00b6ad4f]:hover {
    background-color: transparent;
  }
  .modal-token-item.disabled[data-v-00b6ad4f] {
    opacity: 0.5;
    pointer-events: none;
  }
  .modal-token-name[data-v-00b6ad4f] {
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1rem;
    --tw-text-opacity: 1;
    color: rgba(100, 116, 139, var(--tw-text-opacity));
  }
  .dark .modal-token-name[data-v-00b6ad4f] {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }
  .button-submit[data-v-556e9ece]:disabled {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
    cursor: not-allowed;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  b {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(15, 23, 42, var(--tw-text-opacity));
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .dark body {
    --tw-bg-opacity: 1;
    background-color: #3b066f;
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  *,
  :before,
  :after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
  }
  * {
    --tw-ring-inset: var(--tw-empty);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
  }
  :root {
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
  }
  :-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  :-moz-ui-invalid {
    box-shadow: none;
  }
  ::moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }
  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }


  .container1 {
    width: 100%;
  }
  @media (min-width: 640px) {
    .container1 {
      max-width: 640px;
    }

    
  }
  @media (min-width: 768px) {
    .container1 {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .container1 {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .container1 {
      max-width: 1280px;
    }
  }
  @media (min-width: 1536px) {
    .container1 {
      max-width: 1536px;
    }
  }
  .appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
  }
  .dark .dark\:bg-\[\#160d2c\] {
    --tw-bg-opacity: 1;
    background-color: rgba(22, 13, 44, var(--tw-bg-opacity));
  }
  .bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .bg-slate-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(15, 23, 42, var(--tw-bg-opacity));
  }
  .hover\:bg-slate-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
  }
  .bg-sky-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 165, 233, var(--tw-bg-opacity));
  }
  .hover\:bg-sky-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 189, 248, var(--tw-bg-opacity));
  }
  .bg-green-400\/10 {
    --tw-bg-opacity: 0.1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .hover\:bg-green-400\/20:hover {
    --tw-bg-opacity: 0.2;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .bg-red-400\/10 {
    --tw-bg-opacity: 0.1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .hover\:bg-red-400\/20:hover {
    --tw-bg-opacity: 0.2;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .dark .dark\:bg-\[\#000000\] {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
  }
  .bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .dark .dark\:bg-slate-700,
  .bg-slate-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
  }
  .dark .dark\:bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
  }
  .dark .dark\:bg-slate-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
  }
  .bg-slate-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
  }
  .bg-transparent {
    background-color: transparent;
  }
  .bg-slate-500,
  .dark .dark\:bg-slate-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(100, 116, 139, var(--tw-bg-opacity));
  }
  .group:hover .group-hover\:bg-slate-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
  }
  .dark .group:hover .dark\:group-hover\:bg-slate-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  }
  .hover\:bg-slate-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
  }
  .dark .dark\:hover\:bg-slate-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
  }
  .bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .border-slate-900 {
    --tw-border-opacity: 1;
    border-color: rgba(15, 23, 42, var(--tw-border-opacity));
  }
  .dark .dark\:border-slate-200 {
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }
  .rounded-xl {
    border-radius: 0.75rem;
  }
  .rounded-2xl {
    border-radius: 1rem;
  }
  .rounded-full {
    border-radius: 9999px;
  }
  .rounded-4xl {
    border-radius: 2rem;
  }
  .rounded-lg {
    border-radius: 0.5rem;
  }
  .rounded-md {
    border-radius: 0.375rem;
  }
  .rounded-1\/2 {
    border-radius: 50%;
  }
  .border {
    border-width: 1px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .block {
    display: block;
  }
  .flex1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .grid {
    display: -ms-grid;
    display: grid;
  }
  .hidden {
    display: none;
  }
  .flex1-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .flex1-wrap {
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .items-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex1-end;
    align-items: flex-end;
  }
  .items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .justify-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .justify-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .flex1-auto {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    -webkit-flex1: 1 1 auto;
    flex: 1 1 auto;
  }
  .font-bold {
    font-weight: 700;
  }
  .font-semibold {
    font-weight: 600;
  }
  .font-extrabold {
    font-weight: 800;
  }
  .font-medium {
    font-weight: 500;
  }
  .font-normal {
    font-weight: 400;
  }
  .font-light {
    font-weight: 300;
  }
 
  .text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .text-24px {
    font-size: 24px;
    line-height: 1;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .mr-2 {
    margin-right: 0.5rem;
  }
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .ml-auto {
    margin-left: auto;
  }
  .max-h-\[80vh\] {
    max-height: 80vh;
  }
  .max-w-full {
    max-width: 100%;
  }
  .min-h-screen {
    min-height: 100vh;
  }
  .opacity-50 {
    opacity: 0.5;
  }
  .focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .focus\:outline-solid-slate-300:focus {
    --tw-outline-opacity: 1;
    outline-color: rgba(203, 213, 225, var(--tw-outline-opacity));
  }
  .overflow-y-auto {
    overflow-y: auto;
  }
  .p-4 {
    padding: 1rem;
  }
  .p-2 {
    padding: 0.5rem;
  }
  .p-1 {
    padding: 0.25rem;
  }
  .py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-\[3px\] {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .pb-5 {
    padding-bottom: 1.25rem;
  }
  .pt-16 {
    padding-top: 4rem;
  }
  .pb-4 {
    padding-bottom: 1rem;
  }
  .pointer-events-none {
    pointer-events: none;
  }
  .fixed {
    position: fixed;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .top-1 {
    top: 0.25rem;
  }
  .left-1 {
    left: 0.25rem;
  }
  .top-0 {
    top: 0px;
  }
  .right-0 {
    right: 0px;
  }
  .shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0/0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1), 0 4px 6px -4px rgb(0 0 0/0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
      0 4px 6px -4px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .focus\:ring-offset-slate-50:focus {
    --tw-ring-offset-opacity: 1;
    --tw-ring-offset-color: rgba(248, 250, 252, var(--tw-ring-offset-opacity));
  }
  .focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .focus\:ring-slate-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(148, 163, 184, var(--tw-ring-opacity));
  }
  .text-center {
    text-align: center;
  }
  .text-slate-900 {
    --tw-text-opacity: 1;
    color: rgba(15, 23, 42, var(--tw-text-opacity));
  }
  .hover\:text-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 115, 22, var(--tw-text-opacity));
  }
  .text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .dark .dark\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .dark .dark\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .dark .dark\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .dark .dark\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .text-slate-400 {
    --tw-text-opacity: 1;
    color: rgba(148, 163, 184, var(--tw-text-opacity));
  }
  .group:hover .group-hover\:text-slate-200 {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }
  .dark .group:hover .dark\:group-hover\:text-slate-900 {
    --tw-text-opacity: 1;
    color: rgba(15, 23, 42, var(--tw-text-opacity));
  }
  .text-slate-500 {
    --tw-text-opacity: 1;
    color: rgba(100, 116, 139, var(--tw-text-opacity));
  }
  .truncate {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .hover\:underline:hover {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
  .antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .w-full {
    width: 100%;
  }
  .w-5 {
    width: 1.25rem;
  }
  .w-auto {
    width: auto;
  }
  .w-lg {
    width: 32rem;
  }
  .w-7 {
    width: 1.75rem;
  }
  .w-6 {
    width: 1.5rem;
  }
  .w-10 {
    width: 2.5rem;
  }
  .w-3 {
    width: 0.75rem;
  }
  .z-4 {
    z-index: 4;
  }
  .gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  .gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }
  .gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .gap-x-4 {
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }
  .gap-x-2 {
    -webkit-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }
  .gap-y-2 {
    -webkit-row-gap: 0.5rem;
    -moz-row-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .gap-y-4 {
    -webkit-row-gap: 1rem;
    -moz-row-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }
  .grid-cols-\[minmax\(min-content\,100px\)\,minmax\(auto\,1fr\)\] {
    grid-template-columns: minmax(min-content, 100px) minmax(auto, 1fr);
  }
  .grid-cols-\[minmax\(max-content\,100px\)\,minmax\(auto\,1fr\)\] {
    grid-template-columns: minmax(max-content, 100px) minmax(auto, 1fr);
  }
  .grid-cols-\[40px\,minmax\(auto\,1fr\)\] {
    grid-template-columns: 40px minmax(auto, 1fr);
  }
  .col-span-2 {
    -ms-grid-column-span: span 2 / span 2;
    grid-column: span 2 / span 2;
  }
  .transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-translate-z: 0;
    --tw-rotate: 0;
    --tw-rotate-x: 0;
    --tw-rotate-y: 0;
    --tw-rotate-z: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-scale-z: 1;
    -webkit-transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z))
      rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x))
      rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
    -ms-transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z))
      rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x))
      rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
    transform: translate(var(--tw-translate-x)) translateY(var(--tw-translate-y))
      translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate))
      rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y))
      rotate(var(--tw-rotate-z)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
      scaleZ(var(--tw-scale-z));
  }
  .translate-x-0 {
    --tw-translate-x: 0px;
  }
  .-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .transition {
    -webkit-transition-property: background-color, border-color, color, fill,
      stroke, opacity, -webkit-box-shadow, -webkit-transform, filter,
      backdrop-filter;
    -o-transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform,
      filter, backdrop-filter;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition-duration: 0.15s;
    -o-transition-duration: 0.15s;
    transition-duration: 0.15s;
  }
  .transition-transform {
    -webkit-transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition-duration: 0.15s;
    -o-transition-duration: 0.15s;
    transition-duration: 0.15s;
  }
  .duration-300 {
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  @media (min-width: 640px) {
    .sm\:w-auto {
      width: auto;
    }
  }
  @media (min-width: 1024px) {
    .lg\:block {
      display: block;
    }
    .lg\:text-3xl {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
    .lg\:text-left {
      text-align: left;
    }
  }
  @media (min-width: 1280px) {
    .xl\:h-12 {
      height: 3rem;
    }
    .xl\:w-96 {
      width: 24rem;
    }
    .xl\:gap-5 {
      grid-gap: 1.25rem;
      gap: 1.25rem;
    }
  }
  .Vue-Toastification__container1 {
    z-index: 9999;
    position: fixed;
    padding: 4px;
    width: 600px;
    box-sizing: border-box;
    display: flex;
    min-height: 100%;
    color: #fff;
    flex-direction: column;
    pointer-events: none;
  }
  @media only screen and (min-width: 600px) {
    .Vue-Toastification__container1.top-left,
    .Vue-Toastification__container1.top-right,
    .Vue-Toastification__container1.top-center {
      top: 1em;
    }
    .Vue-Toastification__container1.bottom-left,
    .Vue-Toastification__container1.bottom-right,
    .Vue-Toastification__container1.bottom-center {
      bottom: 1em;
      flex-direction: column-reverse;
    }
    .Vue-Toastification__container1.top-left,
    .Vue-Toastification__container1.bottom-left {
      left: 1em;
    }
    .Vue-Toastification__container1.top-left .Vue-Toastification__toast,
    .Vue-Toastification__container1.bottom-left .Vue-Toastification__toast {
      margin-right: auto;
    }
    @supports not (-moz-appearance: none) {
      .Vue-Toastification__container1.top-left .Vue-Toastification__toast--rtl,
      .Vue-Toastification__container1.bottom-left .Vue-Toastification__toast--rtl {
        margin-right: unset;
        margin-left: auto;
      }
    }
    .Vue-Toastification__container1.top-right,
    .Vue-Toastification__container1.bottom-right {
      right: 1em;
    }
    .Vue-Toastification__container1.top-right .Vue-Toastification__toast,
    .Vue-Toastification__container1.bottom-right .Vue-Toastification__toast {
      margin-left: auto;
    }
    @supports not (-moz-appearance: none) {
      .Vue-Toastification__container1.top-right .Vue-Toastification__toast--rtl,
      .Vue-Toastification__container1.bottom-right
        .Vue-Toastification__toast--rtl {
        margin-left: unset;
        margin-right: auto;
      }
    }
    .Vue-Toastification__container1.top-center,
    .Vue-Toastification__container1.bottom-center {
      left: 50%;
      margin-left: -300px;
    }
    .Vue-Toastification__container1.top-center .Vue-Toastification__toast,
    .Vue-Toastification__container1.bottom-center .Vue-Toastification__toast {
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media only screen and (max-width: 600px) {
    .Vue-Toastification__container1 {
      width: 100vw;
      padding: 0;
      left: 0;
      margin: 0;
    }
    .Vue-Toastification__container1 .Vue-Toastification__toast {
      width: 100%;
    }
    .Vue-Toastification__container1.top-left,
    .Vue-Toastification__container1.top-right,
    .Vue-Toastification__container1.top-center {
      top: 0;
    }
    .Vue-Toastification__container1.bottom-left,
    .Vue-Toastification__container1.bottom-right,
    .Vue-Toastification__container1.bottom-center {
      bottom: 0;
      flex-direction: column-reverse;
    }
  }
  .Vue-Toastification__toast {
    display: inline-flex;
    position: relative;
    max-height: 800px;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 22px 24px;
    border-radius: 8px;
    box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
    justify-content: space-between;

    max-width: 600px;
    min-width: 326px;
    pointer-events: auto;
    overflow: hidden;
    transform: translateZ(0);
    direction: ltr;
  }
  .Vue-Toastification__toast--rtl {
    direction: rtl;
  }
  .Vue-Toastification__toast--default {
    background-color: #1976d2;
    color: #fff;
  }
  .Vue-Toastification__toast--info {
    background-color: #2196f3;
    color: #fff;
  }
  .Vue-Toastification__toast--success {
    background-color: #4caf50;
    color: #fff;
  }
  .Vue-Toastification__toast--error {
    background-color: #ff5252;
    color: #fff;
  }
  .Vue-Toastification__toast--warning {
    background-color: #ffc107;
    color: #fff;
  }
  @media only screen and (max-width: 600px) {
    .Vue-Toastification__toast {
      border-radius: 0;
      margin-bottom: 0.5rem;
    }
  }
  .Vue-Toastification__toast-body {
    flex: 1;
    line-height: 24px;
    font-size: 16px;
    word-break: break-word;
    white-space: pre-wrap;
  }
  .Vue-Toastification__toast-component-body {
    flex: 1;
  }
  .Vue-Toastification__toast.disable-transition {
    animation: none !important;
  }
  .Vue-Toastification__close-button {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0 0 0 10px;
    cursor: pointer;
    transition: 0.3s ease;
    align-items: center;
    color: #fff;
    opacity: 0.3;
    transition: visibility 0s, opacity 0.2s linear;
  }
  .Vue-Toastification__close-button:hover,
  .Vue-Toastification__close-button:focus {
    opacity: 1;
  }
  .Vue-Toastification__toast:not(:hover)
    .Vue-Toastification__close-button.show-on-hover {
    opacity: 0;
  }
  .Vue-Toastification__toast--rtl .Vue-Toastification__close-button {
    padding-left: unset;
    padding-right: 10px;
  }
  @keyframes scale-x-frames {
    0% {
      transform: scaleX(1);
    }
    to {
      transform: scaleX(0);
    }
  }
  .Vue-Toastification__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 10000;
    background-color: #ffffffb3;
    transform-origin: left;
    animation: scale-x-frames linear 1 forwards;
  }
  .Vue-Toastification__toast--rtl .Vue-Toastification__progress-bar {
    right: 0;
    left: unset;
    transform-origin: right;
  }
  .Vue-Toastification__icon {
    margin: auto 18px auto 0;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    transition: 0.3s ease;
    align-items: center;
    width: 20px;
    height: 100%;
  }
  .Vue-Toastification__toast--rtl .Vue-Toastification__icon {
    margin: auto 0 auto 18px;
  }
  @keyframes bounceInRight {
    0%,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      transform: translate3d(3000px, 0, 0);
    }
    60% {
      opacity: 1;
      transform: translate3d(-25px, 0, 0);
    }
    75% {
      transform: translate3d(10px, 0, 0);
    }
    90% {
      transform: translate3d(-5px, 0, 0);
    }
    to {
      transform: none;
    }
  }
  @keyframes bounceOutRight {
    40% {
      opacity: 1;
      transform: translate3d(-20px, 0, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(1000px, 0, 0);
    }
  }
  @keyframes bounceInLeft {
    0%,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      transform: translate3d(-3000px, 0, 0);
    }
    60% {
      opacity: 1;
      transform: translate3d(25px, 0, 0);
    }
    75% {
      transform: translate3d(-10px, 0, 0);
    }
    90% {
      transform: translate3d(5px, 0, 0);
    }
    to {
      transform: none;
    }
  }
  @keyframes bounceOutLeft {
    20% {
      opacity: 1;
      transform: translate3d(20px, 0, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(-2000px, 0, 0);
    }
  }
  @keyframes bounceInUp {
    0%,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      transform: translate3d(0, 3000px, 0);
    }
    60% {
      opacity: 1;
      transform: translate3d(0, -20px, 0);
    }
    75% {
      transform: translate3d(0, 10px, 0);
    }
    90% {
      transform: translate3d(0, -5px, 0);
    }
    to {
      transform: translateZ(0);
    }
  }
  @keyframes bounceOutUp {
    20% {
      transform: translate3d(0, -10px, 0);
    }
    40%,
    45% {
      opacity: 1;
      transform: translate3d(0, 20px, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(0, -2000px, 0);
    }
  }
  @keyframes bounceInDown {
    0%,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      transform: translate3d(0, -3000px, 0);
    }
    60% {
      opacity: 1;
      transform: translate3d(0, 25px, 0);
    }
    75% {
      transform: translate3d(0, -10px, 0);
    }
    90% {
      transform: translate3d(0, 5px, 0);
    }
    to {
      transform: none;
    }
  }
  @keyframes bounceOutDown {
    20% {
      transform: translate3d(0, 10px, 0);
    }
    40%,
    45% {
      opacity: 1;
      transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(0, 2000px, 0);
    }
  }
  .Vue-Toastification__bounce-enter-active.top-left,
  .Vue-Toastification__bounce-enter-active.bottom-left {
    animation-name: bounceInLeft;
  }
  .Vue-Toastification__bounce-enter-active.top-right,
  .Vue-Toastification__bounce-enter-active.bottom-right {
    animation-name: bounceInRight;
  }
  .Vue-Toastification__bounce-enter-active.top-center {
    animation-name: bounceInDown;
  }
  .Vue-Toastification__bounce-enter-active.bottom-center {
    animation-name: bounceInUp;
  }
  .Vue-Toastification__bounce-leave-active:not(.disable-transition).top-left,
  .Vue-Toastification__bounce-leave-active:not(.disable-transition).bottom-left {
    animation-name: bounceOutLeft;
  }
  .Vue-Toastification__bounce-leave-active:not(.disable-transition).top-right,
  .Vue-Toastification__bounce-leave-active:not(.disable-transition).bottom-right {
    animation-name: bounceOutRight;
  }
  .Vue-Toastification__bounce-leave-active:not(.disable-transition).top-center {
    animation-name: bounceOutUp;
  }
  .Vue-Toastification__bounce-leave-active:not(
      .disable-transition
    ).bottom-center {
    animation-name: bounceOutDown;
  }
  .Vue-Toastification__bounce-leave-active,
  .Vue-Toastification__bounce-enter-active {
    animation-duration: 0.75s;
    animation-fill-mode: both;
  }
  .Vue-Toastification__bounce-move {
    transition-timing-function: ease-in-out;
    transition-property: all;
    transition-duration: 0.4s;
  }
  @keyframes fadeOutTop {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-50px);
      opacity: 0;
    }
  }
  @keyframes fadeOutLeft {
    0% {
      transform: translate(0);
      opacity: 1;
    }
    to {
      transform: translate(-50px);
      opacity: 0;
    }
  }
  @keyframes fadeOutBottom {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(50px);
      opacity: 0;
    }
  }
  @keyframes fadeOutRight {
    0% {
      transform: translate(0);
      opacity: 1;
    }
    to {
      transform: translate(50px);
      opacity: 0;
    }
  }
  @keyframes fadeInLeft {
    0% {
      transform: translate(-50px);
      opacity: 0;
    }
    to {
      transform: translate(0);
      opacity: 1;
    }
  }
  @keyframes fadeInRight {
    0% {
      transform: translate(50px);
      opacity: 0;
    }
    to {
      transform: translate(0);
      opacity: 1;
    }
  }
  @keyframes fadeInTop {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fadeInBottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .Vue-Toastification__fade-enter-active.top-left,
  .Vue-Toastification__fade-enter-active.bottom-left {
    animation-name: fadeInLeft;
  }
  .Vue-Toastification__fade-enter-active.top-right,
  .Vue-Toastification__fade-enter-active.bottom-right {
    animation-name: fadeInRight;
  }
  .Vue-Toastification__fade-enter-active.top-center {
    animation-name: fadeInTop;
  }
  .Vue-Toastification__fade-enter-active.bottom-center {
    animation-name: fadeInBottom;
  }
  .Vue-Toastification__fade-leave-active:not(.disable-transition).top-left,
  .Vue-Toastification__fade-leave-active:not(.disable-transition).bottom-left {
    animation-name: fadeOutLeft;
  }
  .Vue-Toastification__fade-leave-active:not(.disable-transition).top-right,
  .Vue-Toastification__fade-leave-active:not(.disable-transition).bottom-right {
    animation-name: fadeOutRight;
  }
  .Vue-Toastification__fade-leave-active:not(.disable-transition).top-center {
    animation-name: fadeOutTop;
  }
  .Vue-Toastification__fade-leave-active:not(.disable-transition).bottom-center {
    animation-name: fadeOutBottom;
  }
  .Vue-Toastification__fade-leave-active,
  .Vue-Toastification__fade-enter-active {
    animation-duration: 0.75s;
    animation-fill-mode: both;
  }
  .Vue-Toastification__fade-move {
    transition-timing-function: ease-in-out;
    transition-property: all;
    transition-duration: 0.4s;
  }
  @keyframes slideInBlurredLeft {
    0% {
      transform: translate(-1000px) scaleX(2.5) scaleY(0.2);
      transform-origin: 100% 50%;
      filter: blur(40px);
      opacity: 0;
    }
    to {
      transform: translate(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slideInBlurredTop {
    0% {
      transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      transform-origin: 50% 0%;
      filter: blur(240px);
      opacity: 0;
    }
    to {
      transform: translateY(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slideInBlurredRight {
    0% {
      transform: translate(1000px) scaleX(2.5) scaleY(0.2);
      transform-origin: 0% 50%;
      filter: blur(40px);
      opacity: 0;
    }
    to {
      transform: translate(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slideInBlurredBottom {
    0% {
      transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
      transform-origin: 50% 100%;
      filter: blur(240px);
      opacity: 0;
    }
    to {
      transform: translateY(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slideOutBlurredTop {
    0% {
      transform: translateY(0) scaleY(1) scaleX(1);
      transform-origin: 50% 0%;
      filter: blur(0);
      opacity: 1;
    }
    to {
      transform: translateY(-1000px) scaleY(2) scaleX(0.2);
      transform-origin: 50% 0%;
      filter: blur(240px);
      opacity: 0;
    }
  }
  @keyframes slideOutBlurredBottom {
    0% {
      transform: translateY(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
    }
    to {
      transform: translateY(1000px) scaleY(2) scaleX(0.2);
      transform-origin: 50% 100%;
      filter: blur(240px);
      opacity: 0;
    }
  }
  @keyframes slideOutBlurredLeft {
    0% {
      transform: translate(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
    }
    to {
      transform: translate(-1000px) scaleX(2) scaleY(0.2);
      transform-origin: 100% 50%;
      filter: blur(40px);
      opacity: 0;
    }
  }
  @keyframes slideOutBlurredRight {
    0% {
      transform: translate(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
    }
    to {
      transform: translate(1000px) scaleX(2) scaleY(0.2);
      transform-origin: 0% 50%;
      filter: blur(40px);
      opacity: 0;
    }
  }
  .Vue-Toastification__slideBlurred-enter-active.top-left,
  .Vue-Toastification__slideBlurred-enter-active.bottom-left {
    animation-name: slideInBlurredLeft;
  }
  .Vue-Toastification__slideBlurred-enter-active.top-right,
  .Vue-Toastification__slideBlurred-enter-active.bottom-right {
    animation-name: slideInBlurredRight;
  }
  .Vue-Toastification__slideBlurred-enter-active.top-center {
    animation-name: slideInBlurredTop;
  }
  .Vue-Toastification__slideBlurred-enter-active.bottom-center {
    animation-name: slideInBlurredBottom;
  }
  .Vue-Toastification__slideBlurred-leave-active:not(
      .disable-transition
    ).top-left,
  .Vue-Toastification__slideBlurred-leave-active:not(
      .disable-transition
    ).bottom-left {
    animation-name: slideOutBlurredLeft;
  }
  .Vue-Toastification__slideBlurred-leave-active:not(
      .disable-transition
    ).top-right,
  .Vue-Toastification__slideBlurred-leave-active:not(
      .disable-transition
    ).bottom-right {
    animation-name: slideOutBlurredRight;
  }
  .Vue-Toastification__slideBlurred-leave-active:not(
      .disable-transition
    ).top-center {
    animation-name: slideOutBlurredTop;
  }
  .Vue-Toastification__slideBlurred-leave-active:not(
      .disable-transition
    ).bottom-center {
    animation-name: slideOutBlurredBottom;
  }
  .Vue-Toastification__slideBlurred-leave-active,
  .Vue-Toastification__slideBlurred-enter-active {
    animation-duration: 0.75s;
    animation-fill-mode: both;
  }
  .Vue-Toastification__slideBlurred-move {
    transition-timing-function: ease-in-out;
    transition-property: all;
    transition-duration: 0.4s;
  }
  .typed-cursor {
    opacity: 1;
    animation: typedjsBlink 0.7s infinite;
    -webkit-animation: typedjsBlink 0.7s infinite;
    animation: typedjsBlink 0.7s infinite;
  }
  @keyframes typedjsBlink {
    50% {
      opacity: 0;
    }
  }
  @-webkit-keyframes typedjsBlink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .typed-fade-out {
    opacity: 0;
    transition: opacity 0.25s;
    -webkit-animation: 0;
    animation: 0;
  }
  





  .tableMarketdata, th, td {
    border: none;
    border-collapse: collapse;
    padding-inline: 15px;
    font-size: 10px;
    margin-left: 15vw;
  
  }


  
  .tableOrder, th, td {
    border: none;
    border-collapse: collapse;
    padding-inline: 15px;
    font-size: 10px;
  
  
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
      outline: none;
  }


  .swiper-pagination-bullet-active {
    background-color: #800080 !important;
}




.navbar2 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(6, 6, 17);
  position: fixed;
  top: 0;
}
.items2 {
  width: 75%;
  display: flex;
  justify-content: space-between;
}
.logo2 {
  padding: 5px 0px;
  display: flex;
  vertical-align: middle;
}
.logo2 h1 {
  font-size: 1rem;
  color: white;
}
.menu2 {
  width: 25%;
  display: flex;
  justify-content: space-between;
}
.menu2 p {
  color: white;
}
.menu2 p:hover {
  color: rgb(93, 92, 92);
  transition: 0.5s ease;
  cursor: pointer;
}
.heroSection2 {
  width: 100%;
  margin-top: 55px;
  background-color: rgba(1, 1, 27, 0.4);
  display: flex;
  justify-content: center;
  padding: 30px 0px;
}
.heroSection-container2 {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.hero12 p,
h3 {
  text-align: center;
}
.hero22 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 10px;
}
.hero22 img {
  width: 30px;
  height: 30px;
}
.hero2logo2 {
  display: flex;
  align-items: center;
}
.SPNSTAKE2 {
  background-color:#F8F9FA;
  padding: 3rem 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.SPNSTAKE-container2 {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  background-color: #000;
}
.SPNSTAKE-contain2 {
  width: 40%;
  background-color:#000;
  padding: 20px 30px;
}
.header2 {
  border-bottom: 1px solid rgb(255, 255, 255, 0.1);
}
.header2 h1 {
  color: rgb(255, 255, 255, 0.3);
  font-size: 1.4rem;
}
.duration2 {
  width: 100%;

  padding: 25px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.days2 {
  color: aliceblue;
  text-align: center;
  border: 1px solid rgb(255, 255, 255, 0.3);
  width: 100px;
  padding: 10px 0px;
  margin-bottom: 0.3rem;
}
.Input-amount2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
}
.Input-amount2 p {
  font-weight: bold;
  color: white;
  position: absolute;

}
.Input-amount2 input {
  padding: 20px 0px;
  background-color: rgba(1, 1, 27, 0.4);
  border: none;
  outline: none;

}
.Input-amount2 button {
  height: 40px;
  width: 120px;
  background-color: aliceblue;
  border: none;
  outline: none;
}
.Amount2 {
  width: 40%;
}
.Amount2 div {
  background-color: rgba(255, 255, 255, 0.13);
  padding: 10px;
  margin-bottom: 15px;
}
.Amount2 p {
  color: rgb(255, 255, 255, 0.5);
}
.Amount2 h2 {
  color: rgb(255, 255, 255, 0.5);
}
@media screen and (max-width: 600px) {
  .navbar2 {
    padding: 30px 0px;
  }
  .items2 {
    justify-content: center;
  }
  .menu2 {
    display: none;
  }
  .heroSection2 {
    margin-top: 100px;
  }
  .heroSection-container2 {
    flex-direction: column;
    align-items: center;
  }
  .hero2logo2 {
    flex-direction: column;
  }
  
  .SPNSTAKE-container2 {
    width: 95%;
    flex-direction: column;
  }

  .SPNSTAKE-contain2 {
    width: 95%;
  }
  
  .Amount2 {
    width: 95%;
    margin-top: 10px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
  }
  .Amount2 div {
    width: 100%;
  }
}
